/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { replace } from "lodash"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  CentersOfExcellenceLabels,
  ColorBar,
  HeroGradient,
  QuickLink,
  QuickLinks,
  SectionHeading,
  Units,
  VideoPlayer,
} from "../../components/elements"

const Facilities = ({ data, pageContext }) => {
  const page = data.datoCmsFacility
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={`${replace(page.title, "-", " ")}`}
                />
              </Container>
              <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
            </HeroGradient>
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>

        {/* Video */}
        <Section id="video" sx={{ backgroundColor: "warmgray" }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: ["full"], textAlign: "center" }}>
                <SectionHeading>
                  WVU Medicine Children's Hospital
                </SectionHeading>
              </Box>
              <Box sx={{ mb: 4, width: ["full", "full", "4/5"] }}>
                <VideoPlayer url={page.video.url} />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Units & Centers of Excellence */}
        <Section>
          <Container sx={{ px: "1em" }}>
            <CentersOfExcellenceLabels />

            <Units />
          </Container>
        </Section>

        <QuickLinks>
          {page.quicklinks.map(
            ({ id, linkTitle, internalLinkUrl, extrenalLinkUrl }) => (
              <QuickLink
                key={id}
                to={extrenalLinkUrl || internalLinkUrl}
                title={linkTitle}
              />
            )
          )}
        </QuickLinks>
      </Main>
    </Layout>
  )
}

export default Facilities

export const query = graphql`
  {
    datoCmsFacility {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      quicklinks {
        id
        linkTitle
        internalLinkUrl
        extrenalLinkUrl
      }
      seoMetaTags {
        tags
      }
      video {
        title
        url
      }
    }
  }
`
